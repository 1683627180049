import { erf } from 'mathjs';

import boysLengthAgeZScores from '../data/boys_length_age_zscores.json';
import girlsLengthAgeZScores from '../data/girls_length_age_zscores.json';

/**
 * Finds the closest month's data based on the age in months.
 * @param {Array} data - The z-score data array (boys or girls).
 * @param {number} month - The child's age in months.
 * @returns {Object} - The closest data for the given age.
 */
const getClosestMonthData = (data, month) => {
    return data.reduce((prev, curr) => {
        return Math.abs(curr.Month - month) < Math.abs(prev.Month - month) ? curr : prev;
    });
};

/**
 * Interpolates between two values based on a weight.
 * @param {number} lowerValue - The lower bound value.
 * @param {number} upperValue - The upper bound value.
 * @param {number} weight - The interpolation weight (0 to 1).
 * @returns {number} - The interpolated value.
 */
const interpolate = (lowerValue, upperValue, weight) => {
    return lowerValue + (upperValue - lowerValue) * weight;
};

/**
 * Interpolates the LMS and weight percentiles for fractional months.
 * @param {Array} data - The z-score data array (boys or girls).
 * @param {number} ageInMonths - The child's age in fractional months.
 * @returns {Object} - Interpolated LMS parameters and percentiles.
 */
const getInterpolatedMonthData = (data, ageInMonths) => {
    const lowerMonth = Math.floor(ageInMonths);
    const upperMonth = Math.ceil(ageInMonths);

    if (lowerMonth === upperMonth) {
        return getClosestMonthData(data, lowerMonth);
    }

    const lowerData = getClosestMonthData(data, lowerMonth);
    const upperData = getClosestMonthData(data, upperMonth);
    const weight = ageInMonths - lowerMonth;

    const interpolatedData = {
        L: interpolate(lowerData.L, upperData.L, weight),
        M: interpolate(lowerData.M, upperData.M, weight),
        S: interpolate(lowerData.S, upperData.S, weight),
    };

    // Interpolate percentiles
    const percentiles = [
        'P01', 'P1', 'P3', 'P5', 'P10', 'P15', 'P25',
        'P50', 'P75', 'P85', 'P90', 'P95', 'P97', 'P99', 'P999'
    ];
    percentiles.forEach((percentile) => {
        interpolatedData[percentile] = interpolate(
            lowerData[percentile],
            upperData[percentile],
            weight
        );
    });

    return interpolatedData;
};

/**
 * Calculates the z-score for a given length using LMS parameters.
 * @param {number} length - The child's length.
 * @param {number} L - LMS skewness parameter.
 * @param {number} M - LMS median parameter.
 * @param {number} S - LMS coefficient of variation.
 * @returns {number} - The calculated z-score.
 */
const calculateZScore = (length, L, M, S) => {
    if (L === 0) {
        return Math.log(length / M) / S;
    } else {
        return (((length / M) ** L) - 1) / (L * S);
    }
};

/**
 * Calculates the percentile based on the z-score using the error function.
 * @param {number} zScore - The z-score.
 * @returns {number} - The percentile.
 */
const zScoreToPercentile = (zScore) => {
    const normalCDF = (z) => 0.5 * (1 + erf(z / Math.sqrt(2)));
    return Math.round(100 * normalCDF(zScore));
};

/**
 * Main function to calculate length-to-age percentiles using LMS and direct lookup methods.
 * @param {Object} params - Input parameters (gender, birthDate, measurementDate, length).
 * @returns {Object} - Results of both LMS and direct percentile calculations.
 */
export const calculateLengthToAgePercentiles = ({ gender, birthDate, measurementDate, length }) => {
    const birthDateObj = new Date(birthDate);
    const measurementDateObj = new Date(measurementDate);
    const ageInMonths = (measurementDateObj - birthDateObj) / (1000 * 60 * 60 * 24 * 30); // Fractional months
    const data = gender === 'Boy' ? boysLengthAgeZScores : girlsLengthAgeZScores;

    // Interpolate LMS and percentiles
    const interpolatedData = getInterpolatedMonthData(data, ageInMonths);

    // LMS Percentile Calculation
    const zScore = calculateZScore(length, interpolatedData.L, interpolatedData.M, interpolatedData.S);
    const lmsPercentile = zScoreToPercentile(zScore);

    return {
        lms: { percentile: lmsPercentile, zScore },
        interpolatedData,
        ageInMonths,
    };
};
