import React from 'react';
import { Container, Typography, Box, Tabs, Tab, Link } from '@mui/material';
import { useLocation, Link as RouterLink } from 'react-router-dom';

const LegalPages = () => {
  const location = useLocation();
  const [value, setValue] = React.useState(location.pathname === '/privacy' ? 0 : 1);

  return (
    <Container maxWidth="md" sx={{ py: 8 }}>
      <Tabs value={value} sx={{ mb: 4 }}>
        <Tab 
          label="Privacy Policy" 
          component={RouterLink} 
          to="/privacy"
          onClick={() => setValue(0)}
        />
        <Tab 
          label="Terms of Service" 
          component={RouterLink} 
          to="/terms"
          onClick={() => setValue(1)}
        />
      </Tabs>

      {value === 0 ? (
        <Box>
          <Typography variant="h4" sx={{ mb: 4 }}>Privacy Policy</Typography>
          <Typography variant="body1" paragraph>
            Last updated: December 6th, 2024
          </Typography>

          <Typography variant="h6" sx={{ mb: 2 }}>Information We Collect</Typography>
          <Typography paragraph>
            We collect information you provide directly to us, including your name, email address, and your child's growth measurements. This information is used solely for tracking your child's growth and development.
          </Typography>

          <Typography variant="h6" sx={{ mb: 2 }}>How We Use Your Information</Typography>
          <Typography paragraph>
            - To provide and maintain our service<br/>
            - To notify you about changes to our service<br/>
            - To provide customer support<br/>
            - To generate anonymous, aggregate statistics
          </Typography>

          <Typography variant="h6" sx={{ mb: 2 }}>Data Security</Typography>
          <Typography paragraph>
            We implement appropriate security measures to protect your personal information. Data is encrypted and stored securely.
          </Typography>

          <Typography variant="h6" sx={{ mb: 2 }}>Your Rights</Typography>
          <Typography paragraph>
            You can access, update, or delete your information at any time through your account settings. Contact us for assistance.
          </Typography>

          <Typography variant="h6" sx={{ mb: 2 }}>Contact Us</Typography>
          <Typography paragraph>
            Email: support@growinginfant.com
          </Typography>
        </Box>
      ) : (
        <Box>
          <Typography variant="h4" sx={{ mb: 4 }}>Terms of Service</Typography>
          <Typography variant="body1" paragraph>
            Last updated: [Current Date]
          </Typography>

          <Typography variant="h6" sx={{ mb: 2 }}>1. Acceptance of Terms</Typography>
          <Typography paragraph>
            By accessing or using Growing Infant, you agree to these Terms of Service.
          </Typography>

          <Typography variant="h6" sx={{ mb: 2 }}>2. Service Description</Typography>
          <Typography paragraph>
            Growing Infant provides growth tracking tools for children. We do not provide medical advice or diagnoses.
          </Typography>

          <Typography variant="h6" sx={{ mb: 2 }}>3. User Responsibilities</Typography>
          <Typography paragraph>
            - Provide accurate information<br/>
            - Maintain account security<br/>
            - Use the service only as intended<br/>
            - Not share account credentials
          </Typography>

          <Typography variant="h6" sx={{ mb: 2 }}>4. Disclaimers</Typography>
          <Typography paragraph>
            The service is provided "as is" without warranties. We are not responsible for medical decisions made based on our tools.
          </Typography>

          <Typography variant="h6" sx={{ mb: 2 }}>5. Limitation of Liability</Typography>
          <Typography paragraph>
            Growing Infant is not liable for any damages arising from service use or inability to access services.
          </Typography>

          <Typography variant="h6" sx={{ mb: 2 }}>6. Changes to Terms</Typography>
          <Typography paragraph>
            We may modify these terms at any time. Continued use constitutes acceptance of changes.
          </Typography>
        </Box>
      )}
    </Container>
  );
};

export default LegalPages;