export const calculateFractionalMonths = (birthDate, measurementDate) => {
    const birth = new Date(birthDate);
    const measurement = new Date(measurementDate);

    const yearsDifference = measurement.getFullYear() - birth.getFullYear();
    const monthsDifference = measurement.getMonth() - birth.getMonth();
    const daysDifference = measurement.getDate() - birth.getDate();

    const totalMonths = yearsDifference * 12 + monthsDifference;
    const fractionalMonths = totalMonths + daysDifference / 30; // Approximate month length as 30 days
    return fractionalMonths;
};

