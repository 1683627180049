import { createClient } from "@supabase/supabase-js";

const anonKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InJ6dHl2bHVibW94c25tZ3RjcnpyIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MzMzOTA1MjAsImV4cCI6MjA0ODk2NjUyMH0.li1_hR5vW8fBPkYx-LkJcYfkMnNcUa36NqGoNxGxQI0'
const supabase = createClient("https://rztyvlubmoxsnmgtcrzr.supabase.co", anonKey);

const GetUsers = async () => {
    const users = await supabase.from("users").select("*");
}

const CreateUser = async (stytch_user_id) => {
    await supabase.from("users").insert([
        { user_id: stytch_user_id },
    ]).select();
}

const CreateUserIfNotExists = async (stytch_user_id) => {
    const user = await GetUserWithId(stytch_user_id);
    if (user.length === 0) {
        await CreateUser(stytch_user_id);
    }
}

const GetUserWithId = async (stytch_user_id) => {
    const { data } = await supabase.from("users").select("*").eq("user_id", stytch_user_id);
    return data;
}

export { CreateUser, CreateUserIfNotExists, GetUsers, GetUserWithId };