import { createClient } from "@supabase/supabase-js";

const anonKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InJ6dHl2bHVibW94c25tZ3RjcnpyIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MzMzOTA1MjAsImV4cCI6MjA0ODk2NjUyMH0.li1_hR5vW8fBPkYx-LkJcYfkMnNcUa36NqGoNxGxQI0'
const supabaseHost = "https://rztyvlubmoxsnmgtcrzr.supabase.co"
const supabase = createClient(supabaseHost, anonKey);

export const GetChildren = async (userId) => {
    try {
        const { data, error } = await supabase
            .from("children")
            .select("*")
            .eq("parent_id", userId);

        if (error) throw error;

        // Cache the data
        if (data?.length > 0) {
            localStorage.setItem('childProfile', JSON.stringify(data[0].profile));
            localStorage.setItem('measurements', JSON.stringify(data[0].measurements || []));
        }

        return data;
    } catch (error) {
        console.error('Error fetching children:', error);
        throw error;
    }
};

export const GetChild = async (childId, parent_id) => {
    try {
        const { data } = await supabase
            .from("children")
            .select("*")
            .eq("child_id", childId)
            .eq("parent_id", parent_id)
            .single();
        return data;
    } catch (error) {
        console.error('Error fetching child:', error);
        throw error;
    }
}

export const UpsertChild = async (child_id, parent_id, profile, measurements = []) => {
    if (child_id === '') {
        return;
    }

    const existingChild = await GetChild(child_id, parent_id);
    if (existingChild === null) {
        // Insert new record if doesn't exist
        const { data, error } = await supabase
            .from("children")
            .insert({
                child_id: child_id,
                parent_id: parent_id,
                profile: profile,
                measurements: measurements
            })
            .select();

        if (error) throw error;

        // Update cache
        localStorage.setItem('childProfile', JSON.stringify(profile));
        localStorage.setItem('measurements', JSON.stringify(measurements));

        return data;
    } else {
        // Update existing record
        const { data, error } = await supabase
            .from("children")
            .update({
                profile: profile,
                measurements: measurements
            })
            .eq('child_id', child_id)
            .eq('parent_id', parent_id)
            .select();

        if (error) throw error;

        // Update cache  
        localStorage.setItem('childProfile', JSON.stringify(profile));
        localStorage.setItem('measurements', JSON.stringify(measurements));

        return data;
    }
};

export const AddMeasurement = async (childId, measurement) => {
    try {
        // First get current measurements
        const { data: currentData } = await supabase
            .from("children")
            .select("measurements")
            .eq('id', childId)
            .single();

        const updatedMeasurements = [...(currentData.measurements || []), measurement];

        // Update with new measurement
        const { data, error } = await supabase
            .from("children")
            .update({ measurements: updatedMeasurements })
            .eq('child_id', childId)
            .select();

        if (error) throw error;

        // Update cache
        localStorage.setItem('measurements', JSON.stringify(updatedMeasurements));

        return data;
    } catch (error) {
        console.error('Error adding measurement:', error);
        throw error;
    }
};