import React, { useState, useEffect } from 'react';
import {
    Box,
    Collapse,
    Button,
    TextField,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormControl,
    Menu,
    InputAdornment,
    MenuItem,
    Paper,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TablePagination,
    IconButton,
    ButtonGroup,
    Typography,
    ToggleButton,
    ToggleButtonGroup,
    Divider,
    Avatar,
    Icon,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Container,
    Fade,
} from '@mui/material';

import {
    Chart as ChartJS,
    LineElement,
    PointElement,
    LinearScale,
    Title,
    Tooltip,
    Legend,
    CategoryScale,
} from 'chart.js';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Line } from 'react-chartjs-2';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ScaleIcon from '@mui/icons-material/Scale';
import HeightIcon from '@mui/icons-material/Height';
import FaceIcon from '@mui/icons-material/Face';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import GrowingInfantLogo from '../assets/growing_infant_logo.png';

import { GetChildren, UpsertChild } from '../services/ChildService';
import { GetUserId, LogOut } from '../services/AuthService';

// Import all your utility functions and data
import { calculatePercentiles } from '../utils/calculations';
import { calculateLengthToAgePercentiles } from '../utils/lengthCalculations';
import { calculateHcfaPercentiles } from '../utils/headCircumCalculations';
import { calculateFractionalMonths } from '../utils/ageCalculations';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';

// Import all your data files
import girlsWeightData from '../data/girlsWeightData.json';
import boysWeightData from '../data/boysWeightData.json';
import lhfaBoysData from '../data/lhfa_boys_data.json';
import lhfaGirlsData from '../data/lhfa_girls_data.json';
import hcfaBoysData from '../data/tab_hcfa_boys_p_0_5.json';
import hcfaGirlsData from '../data/tab_hcfa_girls_p_0_5.json';
import girlsWeightDataLbs from '../data/girlsWeightData_lbs.json';
import boysWeightDataLbs from '../data/boysWeightData_lbs.json';
import lhfaGirlsDataInches from '../data/lhfa_girls_data_inches.json';
import lhfaBoysDataInches from '../data/lhfa_boys_data_inches.json';
import hcfaGirlsDataInches from '../data/tab_hcfa_girls_p_0_5_inches.json';
import hcfaBoysDataInches from '../data/tab_hcfa_boys_p_0_5_inches.json';

// Register ChartJS components
ChartJS.register(
    LineElement,
    PointElement,
    LinearScale,
    Title,
    Tooltip,
    Legend,
    CategoryScale
);

// Common styles that will be reused
const commonStyles = {
    card: {
        border: '1px solid rgba(0, 0, 0, 0.12)',
        borderRadius: 1,
        bgcolor: 'white',
        boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
        transition: 'box-shadow 0.3s ease-in-out',
        '&:hover': {
            boxShadow: '0 4px 8px rgba(0,0,0,0.1)'
        }
    },
    button: {
        primary: {
            bgcolor: '#4caf50',
            color: 'white',
            '&:hover': {
                bgcolor: '#43a047'
            },
            textTransform: 'none'
        },
        secondary: {
            color: 'text.primary',
            borderColor: 'rgba(0, 0, 0, 0.23)',
            '&:hover': {
                borderColor: 'rgba(0, 0, 0, 0.87)',
                bgcolor: 'rgba(0, 0, 0, 0.04)'
            },
            textTransform: 'none'
        }
    }
};

const GrowthCalculator = () => {
    // Basic state management
    const [isInitialLoad, setIsInitialLoad] = useState(true);
    const [measurementDate, setMeasurementDate] = useState(null);
    const [weight, setWeight] = useState('');
    const [height, setHeight] = useState('');
    const [headCircumference, setHeadCircumference] = useState('');
    const [measurements, setMeasurements] = useState([]);
    const [selectedGraph, setSelectedGraph] = useState('Weight');

    // UI state management
    const [isEditingProfile, setIsEditingProfile] = useState(false);
    const [isAddMeasurementFormVisible, setIsAddMeasurementFormVisible] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [hoveredRow, setHoveredRow] = useState(null);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [measurementToDelete, setMeasurementToDelete] = useState(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    // if the user tries to add a measurement without setting the child's profile
    const [showErrorDialog, setShowErrorDialog] = useState(false);
    const [errorDialogTitle, setErrorDialogTitle] = useState('Error');
    const [errorDialogMessage, setErrorDialogMessage] = useState('');

    // Child profile state with enhanced defaults
    const [childProfile, setChildProfile] = useState({
        id: '',
        name: 'Child Name',
        gender: 'Girl',
        birthDate: null,
        weightUnit: 'kg',
        heightUnit: 'cm',
    });

    // Navigation and menu state
    const navigate = useNavigate();
    const open = Boolean(anchorEl);

    // Initial data loading effect
    useEffect(() => {
        const loadInitialData = async () => {
            setLoading(true);
            try {
                // Try to load from cache first
                const storedChildProfile = localStorage.getItem('childProfile');
                const storedMeasurements = localStorage.getItem('measurements');

                if (storedChildProfile) {
                    const parsedChildProfile = JSON.parse(storedChildProfile);
                    parsedChildProfile.birthDate = parsedChildProfile.birthDate ?
                        new Date(parsedChildProfile.birthDate) : null;
                    setChildProfile(parsedChildProfile);
                }

                if (storedMeasurements) {
                    const parsedMeasurements = JSON.parse(storedMeasurements).map(measurement => ({
                        ...measurement,
                        measurementDate: measurement.measurementDate ?
                            new Date(measurement.measurementDate) : null,
                    }));
                    setMeasurements(parsedMeasurements);
                }

                // Then fetch from Supabase to ensure we have latest data
                const userId = GetUserId();
                const children = await GetChildren(userId);
                if (children?.length > 0) {
                    const child = children[0];
                    setChildProfile(child.profile);
                    setMeasurements(child.measurements || []);
                }

            } catch (error) {
                console.error('Error loading data:', error);
                setError('Failed to load saved data');
                setMeasurements([]);
            } finally {
                setLoading(false);
                setIsInitialLoad(false);
            }
        };

        loadInitialData();
    }, []);

    // Save data effect
    useEffect(() => {
        if (isInitialLoad) return;

        const saveData = async () => {
            if (childProfile.id === '')
                return;

            try {
                const userId = GetUserId();
                await UpsertChild(childProfile.id, userId, childProfile, measurements);
            } catch (error) {
                console.error('Error saving data:', error);
                setError('Failed to save data');
            }
        };

        // Debounce the save operation
        const timeoutId = setTimeout(saveData, 1000);
        return () => clearTimeout(timeoutId);
    }, [measurements, childProfile, isInitialLoad]);

    // Data preparation for graphs
    const weightDataKgs = childProfile.gender === 'Boy' ? boysWeightData : girlsWeightData;
    const heightDataCms = childProfile.gender === 'Boy' ? lhfaBoysData : lhfaGirlsData;
    const hcfaDataCms = childProfile.gender === 'Boy' ? hcfaBoysData : hcfaGirlsData;
    const weightDataLbs = childProfile.gender === 'Boy' ? boysWeightDataLbs : girlsWeightDataLbs;
    const heightDataInches = childProfile.gender === 'Boy' ? lhfaBoysDataInches : lhfaGirlsDataInches;
    const hcfaDataInches = childProfile.gender === 'Boy' ? hcfaBoysDataInches : hcfaGirlsDataInches;

    const weightData = childProfile.weightUnit === 'kg' ? weightDataKgs : weightDataLbs;
    const heightData = childProfile.heightUnit === 'cm' ? heightDataCms : heightDataInches;
    const hcfaData = childProfile.heightUnit === 'cm' ? hcfaDataCms : hcfaDataInches;

    // Event Handlers
    const handleGenderChange = (event, newGender) => {
        if (newGender !== null) {
            setChildProfile({ ...childProfile, gender: newGender });
        }
    };

    const handleWeightUnitChange = (event, newUnit) => {
        if (newUnit !== null) {
            setChildProfile({ ...childProfile, weightUnit: newUnit });
        }
    };

    const handleLengthUnitChange = (event, newUnit) => {
        if (newUnit !== null) {
            setChildProfile({ ...childProfile, heightUnit: newUnit });
        }
    };

    const handleSaveProfile = (e) => {
        e.preventDefault();
        setIsEditingProfile(false);
        // Add success feedback
        if (childProfile.id === '') {
            setChildProfile({ ...childProfile, id: crypto.randomUUID() });
        }
        UpsertChild(childProfile.id, GetUserId(), childProfile, measurements);
    };

    const handleCalculatePercentile = () => {
        try {
            const weightPercentile = calculatePercentiles({
                gender: childProfile.gender,
                birthDate: childProfile.birthDate,
                measurementDate,
                weight: parseFloat(weight),
            });

            const lengthPercentile = calculateLengthToAgePercentiles({
                gender: childProfile.gender,
                birthDate: childProfile.birthDate,
                measurementDate,
                length: parseFloat(height),
            });

            const headCircumferencePercentile = calculateHcfaPercentiles({
                gender: childProfile.gender,
                birthDate: childProfile.birthDate,
                measurementDate,
                headCircumference: parseFloat(headCircumference),
            });

            return {
                weight: weightPercentile,
                length: lengthPercentile,
                headCircumference: headCircumferencePercentile,
            };
        } catch (error) {
            console.error('Error calculating percentiles:', error);
            setError('Failed to calculate percentiles');
            return null;
        }
    };

    const handleAddMeasurement = () => {
        if (!measurementDate && (!weight || !height || !headCircumference)) {
            setShowErrorDialog(true);
            setErrorDialogTitle('Measurement Incomplete');
            setErrorDialogMessage('Please enter date and at least one measurement.');
            return;
        }
        try {
            const percentileResult = handleCalculatePercentile();
            if (!percentileResult) return;

            const newMeasurement = {
                id: Date.now(),
                measurementDate: measurementDate,
                weight: `${weight} ${childProfile.weightUnit}`,
                height: `${height} ${childProfile.heightUnit}`,
                headCircumference: `${headCircumference} ${childProfile.heightUnit}`,
                weightPercentile: percentileResult.weight.lms.percentile,
                lengthPercentile: percentileResult.length.lms.percentile,
                headCircumferencePercentile: percentileResult.headCircumference.lms.percentile,
            };

            setMeasurements(prevMeasurements =>
                [...prevMeasurements, newMeasurement].sort((a, b) =>
                    new Date(b.measurementDate) - new Date(a.measurementDate)
                )
            );

            // Reset form
            setMeasurementDate(null);
            setWeight('');
            setHeight('');
            setHeadCircumference('');
            setIsAddMeasurementFormVisible(false);

            // Show success message
            setSnackbarMessage('Measurement added successfully');
            setSnackbarOpen(true);
        } catch (error) {
            console.error('Error adding measurement:', error);
            setError('Failed to add measurement');
        }
    };

    const handleAddMeasurementClicked = () => {
        if (!childProfile ||
            !childProfile.birthDate ||
            !childProfile.gender ||
            !childProfile.name) {
            setShowErrorDialog(true);
            setErrorDialogTitle('Profile Incomplete');
            setErrorDialogMessage('Please complete the child\'s profile before adding measurements.');
            return;
        }

        setIsAddMeasurementFormVisible(true);
    }

    const handleDismissErrorDialog = () => {
        setShowErrorDialog(false);
        setErrorDialogTitle('Error');
        setErrorDialogMessage('');
    }

    const handleDeleteClick = (measurementId) => {
        setMeasurementToDelete(measurementId);
        setIsDeleteDialogOpen(true);
    };

    const handleDeleteConfirmation = () => {
        setMeasurements(prevMeasurements =>
            prevMeasurements.filter(measurement => measurement.id !== measurementToDelete)
        );
        setIsDeleteDialogOpen(false);
        setMeasurementToDelete(null);

        // Show success message
        setSnackbarMessage('Measurement deleted successfully');
        setSnackbarOpen(true);
    };

    // Settings and Navigation Handlers
    const handleOpenSettings = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseSettings = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        handleCloseSettings();
        LogOut().then(() => {
            localStorage.removeItem('childProfile');
            localStorage.removeItem('measurements');
            navigate('/auth');
        })
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }

    const yAxisLabel = () => {
        switch (selectedGraph) {
            case 'Weight':
                return `Weight (${childProfile.weightUnit})`;
            case 'Height':
                return `Height (${childProfile.heightUnit})`;
            case 'Head Circumference':
                return `Head Circumference (${childProfile.heightUnit})`;
            default:
                return `Weight (${childProfile.weightUnit})`;
        }
    }

    // Graph Related Handlers
    const getUserMeasurementData = () => {
        if (measurements.length === 0) {
            return [];
        }
        if (selectedGraph === 'Weight') {
            return measurements.map((measurement) => ({
                x: calculateFractionalMonths(childProfile.birthDate, measurement.measurementDate),
                y: parseFloat(measurement.weight.split(' ')[0]),
            }));
        }
        if (selectedGraph === 'Height') {
            return measurements.map((measurement) => ({
                x: calculateFractionalMonths(childProfile.birthDate, measurement.measurementDate),
                y: parseFloat(measurement.height.split(' ')[0]),
            }));
        }
        if (selectedGraph === 'Head Circumference') {
            return measurements.map((measurement) => ({
                x: calculateFractionalMonths(childProfile.birthDate, measurement.measurementDate),
                y: parseFloat(measurement.headCircumference.split(' ')[0]),
            }));
        }
    }

    const getGraphData = () => {
        let primaryData;
        switch (selectedGraph) {
            case 'Weight':
                primaryData = weightData;
                break;
            case 'Height':
                primaryData = heightData;
                break;
            case 'Head Circumference':
                primaryData = hcfaData;
                break;
            default:
                primaryData = weightData;
        }

        return {
            labels: primaryData.filter(data => data.Month <= 24).map(data => data.Month),
            datasets: [
                {
                    label: '3rd Percentile',
                    data: primaryData.filter(data => data.Month <= 24).map(data => data.P3),
                    borderWidth: 1,
                    borderColor: 'rgba(255, 99, 132, 1)',
                    fill: false,
                },
                {
                    label: '15th Percentile',
                    data: primaryData.filter(data => data.Month <= 24).map(data => data.P15),
                    borderWidth: 1,
                    borderColor: 'rgba(255, 159, 64, 1)',
                    fill: false,
                },
                {
                    label: '50th Percentile',
                    data: primaryData.filter(data => data.Month <= 24).map(data => data.P50),
                    borderWidth: 1,
                    borderColor: 'rgba(54, 162, 235, 1)',
                    fill: false,
                },
                {
                    label: '85th Percentile',
                    data: primaryData.filter(data => data.Month <= 24).map(data => data.P85),
                    borderWidth: 1,
                    borderColor: 'rgba(153, 102, 255, 1)',
                    fill: false,
                },
                {
                    label: '97th Percentile',
                    data: primaryData.filter(data => data.Month <= 24).map(data => data.P97),
                    borderWidth: 1,
                    borderColor: 'rgba(75, 192, 192, 1)',
                    fill: false,
                },
                {
                    label: childProfile.name,
                    data: getUserMeasurementData(),
                    borderColor: 'rgba(0, 0, 0, 1)',
                    backgroundColor: 'rgba(0, 0, 0, 1)',
                    pointStyle: 'rectRot',
                    pointRadius: 5,
                    showLine: false,
                },
            ],
        };
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Container maxWidth="xl" sx={{ py: 4 }}>
                {/* Header */}
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    mb: 4,
                    pb: 2,
                    borderBottom: '1px solid rgba(0, 0, 0, 0.08)'
                }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                        <img
                            src={GrowingInfantLogo}
                            alt="Growing Infant Logo"
                            style={{
                                height: '48px',
                                width: 'auto'
                            }}
                        />
                        <Typography
                            variant="h5"
                            sx={{
                                fontWeight: 600,
                                color: 'text.primary'
                            }}
                        >
                            Growing Infant
                        </Typography>
                    </Box>

                    <Button
                        onClick={handleOpenSettings}
                        startIcon={<SettingsIcon />}
                        sx={{
                            ...commonStyles.button.secondary,
                            borderRadius: '8px',
                            px: 2,
                            py: 1
                        }}
                    >
                        Settings
                    </Button>

                    <Menu
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleCloseSettings}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        PaperProps={{
                            elevation: 2,
                            sx: {
                                mt: 1,
                                borderRadius: '8px',
                                minWidth: '150px'
                            }
                        }}
                    >
                        <MenuItem
                            onClick={handleLogout}
                            sx={{
                                py: 1.5,
                                gap: 1.5,
                                '&:hover': {
                                    bgcolor: 'rgba(0, 0, 0, 0.04)'
                                }
                            }}
                        >
                            <LogoutIcon fontSize="small" />
                            <Typography>Logout</Typography>
                        </MenuItem>
                    </Menu>
                </Box>

                {/* Main Content Grid */}
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
                    {/* Child Profile Card */}
                    <Paper
                        elevation={0}
                        sx={{
                            ...commonStyles.card,
                            p: 3
                        }}
                    >
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            justifyContent: 'space-between'
                        }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                <Avatar
                                    sx={{
                                        width: 56,
                                        height: 56,
                                        bgcolor: 'primary.light',
                                        color: 'primary.dark',
                                        fontSize: '1.5rem',
                                        fontWeight: 600
                                    }}
                                >
                                    {childProfile.name
                                        .split(' ')
                                        .map(word => word[0])
                                        .join('')
                                        .slice(0, 2)
                                        .toUpperCase()
                                    }
                                </Avatar>
                                <Box>
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                        <Typography variant="h6" sx={{ fontWeight: 600 }}>
                                            {childProfile.name}
                                        </Typography>
                                        <Box sx={{
                                            bgcolor: childProfile.gender.toLowerCase() === 'boy' ?
                                                'rgba(25, 118, 210, 0.08)' :
                                                'rgba(233, 30, 99, 0.08)',
                                            color: childProfile.gender.toLowerCase() === 'boy' ?
                                                'primary.main' :
                                                'error.main',
                                            px: 1.5,
                                            py: 0.5,
                                            borderRadius: '6px',
                                            fontSize: '0.75rem',
                                            fontWeight: 600,
                                            textTransform: 'uppercase'
                                        }}>
                                            {childProfile.gender}
                                        </Box>
                                    </Box>
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            color: 'text.secondary',
                                            mt: 0.5
                                        }}
                                    >
                                        Date of birth: {format(new Date(childProfile.birthDate), 'MMMM d, yyyy')}
                                    </Typography>
                                </Box>
                            </Box>

                            {!isEditingProfile && (
                                <IconButton
                                    onClick={() => setIsEditingProfile(true)}
                                    sx={{
                                        color: 'text.secondary',
                                        '&:hover': {
                                            bgcolor: 'rgba(0, 0, 0, 0.04)'
                                        }
                                    }}
                                >
                                    <EditIcon />
                                </IconButton>
                            )}
                        </Box>
                    </Paper>
                </Box>

                {/* Profile Edit Form */}
                <Collapse in={isEditingProfile}>
                    <Box sx={{ mt: 3 }}>
                        <Box sx={{
                            display: 'flex',
                            gap: 3,
                            '& > *': { flex: 1 }
                        }}>
                            <TextField
                                label="Child Name"
                                value={childProfile.name}
                                fullWidth
                                onChange={(e) => setChildProfile({ ...childProfile, name: e.target.value })}
                                sx={{ '& .MuiOutlinedInput-root': { bgcolor: 'background.paper' } }}
                            />

                            <ToggleButtonGroup
                                value={childProfile.gender}
                                exclusive
                                onChange={(_, value) => value && handleGenderChange(_, value)}
                                fullWidth
                                sx={{
                                    '& .MuiToggleButton-root': {
                                        border: '1px solid rgba(0, 0, 0, 0.12)',
                                        '&.Mui-selected': {
                                            bgcolor: childProfile.gender === 'Boy' ?
                                                'rgba(25, 118, 210, 0.08)' :
                                                'rgba(233, 30, 99, 0.08)',
                                            color: childProfile.gender === 'Boy' ?
                                                'primary.main' :
                                                'error.main',
                                        }
                                    }
                                }}
                            >
                                <ToggleButton value="Girl">Girl</ToggleButton>
                                <ToggleButton value="Boy">Boy</ToggleButton>
                            </ToggleButtonGroup>

                            <DatePicker
                                label="Date of Birth"
                                value={childProfile.birthDate ? new Date(childProfile.birthDate) : new Date()}
                                onChange={(newValue) => setChildProfile({ ...childProfile, birthDate: newValue })}
                                slotProps={{
                                    textField: {
                                        fullWidth: true,
                                        sx: { '& .MuiOutlinedInput-root': { bgcolor: 'background.paper' } }
                                    }
                                }}
                            />
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            gap: 1,
                            mt: 3
                        }}>
                            <Button
                                variant="outlined"
                                onClick={() => setIsEditingProfile(false)}
                                startIcon={<CloseIcon />}
                                sx={commonStyles.button.secondary}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="contained"
                                onClick={handleSaveProfile}
                                startIcon={<SaveIcon />}
                                sx={commonStyles.button.primary}
                            >
                                Save Profile
                            </Button>
                        </Box>
                    </Box>
                </Collapse>

                {/* Measurements Section */}
                <Paper sx={{ ...commonStyles.card, mt: 4, p: 3 }}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        mb: 3
                    }}>
                        <Typography variant="h6" sx={{ fontWeight: 600 }}>
                            Measurements
                        </Typography>

                        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>

                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <Typography variant="body2" color="text.secondary">Weight:</Typography>
                                <ToggleButtonGroup
                                    value={childProfile.weightUnit}
                                    exclusive
                                    onChange={(_, value) => value && handleWeightUnitChange(_, value)}
                                    size="small"
                                >
                                    <ToggleButton value="kg">kg</ToggleButton>
                                    <ToggleButton value="lbs">lbs</ToggleButton>
                                </ToggleButtonGroup>
                            </Box>

                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <Typography variant="body2" color="text.secondary">Height:</Typography>
                                <ToggleButtonGroup
                                    value={childProfile.heightUnit}
                                    exclusive
                                    onChange={(_, value) => value && handleLengthUnitChange(_, value)}
                                    size="small"
                                >
                                    <ToggleButton value="cm">cm</ToggleButton>
                                    <ToggleButton value="in">in</ToggleButton>
                                </ToggleButtonGroup>
                            </Box>

                            {!isAddMeasurementFormVisible && (
                                <Button
                                    onClick={() => handleAddMeasurementClicked()}
                                    variant="contained"
                                    startIcon={<AddIcon />}
                                    sx={commonStyles.button.primary}
                                >
                                    Add Measurement
                                </Button>
                            )}
                        </Box>
                    </Box>

                    <Collapse in={isAddMeasurementFormVisible}>
                        <Paper sx={{
                            p: 3,
                            mb: 3,
                            bgcolor: 'background.default',
                            border: '1px solid rgba(0, 0, 0, 0.12)'
                        }}>
                            <Box sx={{
                                display: 'flex',
                                gap: 3,
                                '& > *': { flex: 1 }
                            }}>
                                <DatePicker
                                    label="Measurement date"
                                    value={measurementDate}
                                    onChange={(newValue) => setMeasurementDate(newValue)}
                                    slotProps={{
                                        textField: {
                                            fullWidth: true,
                                            required: true,
                                            sx: { '& .MuiOutlinedInput-root': { bgcolor: 'background.paper' } }
                                        }
                                    }}
                                />

                                <TextField
                                    label="Weight"
                                    type="number"
                                    value={weight}
                                    onChange={(e) => setWeight(e.target.value)}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">{childProfile.weightUnit}</InputAdornment>,
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <ScaleIcon color="action" />
                                            </InputAdornment>
                                        ),
                                    }}
                                    sx={{ '& .MuiOutlinedInput-root': { bgcolor: 'background.paper' } }}
                                />

                                <TextField
                                    label="Height"
                                    type="number"
                                    value={height}
                                    onChange={(e) => setHeight(e.target.value)}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">{childProfile.heightUnit}</InputAdornment>,
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <HeightIcon color="action" />
                                            </InputAdornment>
                                        ),
                                    }}
                                    sx={{ '& .MuiOutlinedInput-root': { bgcolor: 'background.paper' } }}
                                />

                                <TextField
                                    label="Head Circ."
                                    type="number"
                                    value={headCircumference}
                                    onChange={(e) => setHeadCircumference(e.target.value)}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">{childProfile.heightUnit}</InputAdornment>,
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <FaceIcon color="action" />
                                            </InputAdornment>
                                        ),
                                    }}
                                    sx={{ '& .MuiOutlinedInput-root': { bgcolor: 'background.paper' } }}
                                />
                            </Box>

                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                gap: 1,
                                mt: 3
                            }}>
                                <Button
                                    variant="outlined"
                                    onClick={() => setIsAddMeasurementFormVisible(false)}
                                    startIcon={<CloseIcon />}
                                    sx={commonStyles.button.secondary}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    variant="contained"
                                    onClick={handleAddMeasurement}
                                    startIcon={<SaveIcon />}
                                    sx={commonStyles.button.primary}
                                >
                                    Save Measurement
                                </Button>
                            </Box>
                        </Paper>
                    </Collapse>

                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ fontWeight: 600, color: 'text.secondary' }}>DATE</TableCell>
                                    <TableCell sx={{ fontWeight: 600, color: 'text.secondary' }}>WEIGHT ({childProfile.weightUnit})</TableCell>
                                    <TableCell sx={{ fontWeight: 600, color: 'text.secondary' }}>HEIGHT ({childProfile.heightUnit})</TableCell>
                                    <TableCell sx={{ fontWeight: 600, color: 'text.secondary' }}>HEAD CIRC. ({childProfile.heightUnit})</TableCell>
                                    <TableCell sx={{ fontWeight: 600, color: 'text.secondary' }}>WEIGHT %</TableCell>
                                    <TableCell sx={{ fontWeight: 600, color: 'text.secondary' }}>LENGTH %</TableCell>
                                    <TableCell sx={{ fontWeight: 600, color: 'text.secondary' }}>HEAD CIRC %</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {measurements
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((measurement) => (
                                        <TableRow
                                            key={measurement.id}
                                            onMouseEnter={() => setHoveredRow(measurement.id)}
                                            onMouseLeave={() => setHoveredRow(null)}
                                            sx={{
                                                '&:hover': {
                                                    bgcolor: 'rgba(0, 0, 0, 0.02)'
                                                }
                                            }}
                                        >
                                            <TableCell>
                                                {format(new Date(measurement.measurementDate), 'MMM d, yyyy')}
                                            </TableCell>
                                            <TableCell>{measurement.weight.split(' ')[0]}</TableCell>
                                            <TableCell>{measurement.height.split(' ')[0]}</TableCell>
                                            <TableCell>{measurement.headCircumference.split(' ')[0]}</TableCell>
                                            <TableCell>{measurement.weightPercentile}</TableCell>
                                            <TableCell>{measurement.lengthPercentile}</TableCell>
                                            <TableCell>{measurement.headCircumferencePercentile}</TableCell>
                                            <TableCell>
                                                <Fade in={hoveredRow === measurement.id}>
                                                    <IconButton
                                                        size="small"
                                                        onClick={() => handleDeleteClick(measurement.id)}
                                                        sx={{
                                                            color: 'error.main',
                                                            '&:hover': {
                                                                bgcolor: 'error.light'
                                                            }
                                                        }}
                                                    >
                                                        <DeleteIcon fontSize="small" />
                                                    </IconButton>
                                                </Fade>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                        <TablePagination
                            component="div"
                            count={measurements.length}
                            page={page}
                            onPageChange={handleChangePage}
                            rowsPerPage={rowsPerPage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            rowsPerPageOptions={[5, 10, 25]}
                        />
                    </TableContainer>
                </Paper>

                {/* Growth Chart Section */}
                <Paper sx={{ ...commonStyles.card, mt: 4, p: 3 }}>
                    <ButtonGroup
                        variant="outlined"
                        sx={{
                            mb: 3,
                            display: 'flex',
                            justifyContent: 'center',
                            '& .MuiButton-root': {
                                color: '#4caf50',
                                borderColor: '#4caf50',
                                px: 4,
                                '&:hover': {
                                    borderColor: '#43a047',
                                    backgroundColor: 'rgba(76, 175, 80, 0.04)',
                                },
                                '&.Mui-selected': {
                                    backgroundColor: '#4caf50',
                                    color: 'white',
                                },
                                '&.MuiButton-contained': {
                                    backgroundColor: '#4caf50',
                                    color: 'white',
                                },
                            },
                        }}
                    >
                        {['Weight', 'Height', 'Head Circumference'].map((type) => (
                            <Button
                                key={type}
                                variant={selectedGraph === type ? 'contained' : 'outlined'}
                                onClick={() => setSelectedGraph(type)}
                                sx={{
                                    ...(selectedGraph === type && {
                                        bgcolor: '#4caf50',
                                        color: 'white',
                                        '&:hover': {
                                            bgcolor: '#43a047'
                                        }
                                    })
                                }}
                            >
                                {type}
                            </Button>
                        ))}
                    </ButtonGroup>

                    <Box sx={{ height: '400px', position: 'relative' }}>
                        <Line
                            data={getGraphData()}
                            options={{
                                responsive: true,
                                maintainAspectRatio: false,
                                scales: {
                                    x: {
                                        type: 'linear',
                                        title: {
                                            display: true,
                                            text: 'Age (Months)',
                                            font: {
                                                size: 14,
                                                weight: 500
                                            }
                                        },
                                        ticks: {
                                            stepSize: 1
                                        }
                                    },
                                    y: {
                                        title: {
                                            display: true,
                                            text: yAxisLabel(),
                                            font: {
                                                size: 14,
                                                weight: 500
                                            }
                                        }
                                    }
                                },
                                plugins: {
                                    legend: {
                                        position: 'top',
                                        align: 'center',
                                        labels: {
                                            boxWidth: 40,
                                            boxHeight: 1,
                                            padding: 20,
                                            usePointStyle: false,
                                            generateLabels: (chart) => {
                                                const originalLabels = ChartJS.defaults.plugins.legend.labels.generateLabels(chart);
                                                return originalLabels.map(label => ({
                                                    ...label,
                                                    lineCap: 'butt',
                                                    lineWidth: 1,
                                                    pointStyle: undefined,
                                                }));
                                            }
                                        }
                                    },
                                    tooltip: {
                                        callbacks: {
                                            title: (tooltipItems) => {
                                                return `${tooltipItems[0].parsed.x.toFixed(1)} Months`;
                                            },
                                            label: (context) => {
                                                const unit = selectedGraph === 'Weight' ? childProfile.weightUnit
                                                    : selectedGraph === 'Height' ? childProfile.heightUnit
                                                        : childProfile.heightUnit;

                                                if (context.dataset.label === childProfile.name) {
                                                    const measurement = measurements.find(m =>
                                                        calculateFractionalMonths(childProfile.birthDate, m.measurementDate).toFixed(1) ===
                                                        context.parsed.x.toFixed(1)
                                                    );

                                                    if (measurement) {
                                                        const percentileKey = selectedGraph === 'Weight' ? 'weightPercentile'
                                                            : selectedGraph === 'Height' ? 'lengthPercentile'
                                                                : 'headCircumferencePercentile';

                                                        return `${context.dataset.label}: ${context.parsed.y}${unit} (${measurement[percentileKey]}th percentile)`;
                                                    }
                                                }

                                                return `${context.dataset.label}: ${context.parsed.y}${unit}`;
                                            }
                                        }
                                    }
                                }
                            }}
                        />
                    </Box>
                </Paper>

                {/* Delete Confirmation Dialog */}
                <Dialog
                    open={isDeleteDialogOpen}
                    onClose={() => setIsDeleteDialogOpen(false)}
                    maxWidth="xs"
                    fullWidth
                    PaperProps={{
                        elevation: 2,
                        sx: { borderRadius: 2 }
                    }}
                >
                    <DialogTitle sx={{ pb: 1 }}>Confirm Delete</DialogTitle>
                    <DialogContent>
                        <Typography>
                            Are you sure you want to delete this measurement? This action cannot be undone.
                        </Typography>
                    </DialogContent>
                    <DialogActions sx={{ p: 2, pt: 1 }}>
                        <Button
                            onClick={() => setIsDeleteDialogOpen(false)}
                            variant="outlined"
                            sx={commonStyles.button.secondary}
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={handleDeleteConfirmation}
                            variant="contained"
                            color="error"
                            sx={{
                                textTransform: 'none'
                            }}
                        >
                            Delete
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* Error dialog */}
                <Dialog
                    open={showErrorDialog}
                    onClose={() => handleDismissErrorDialog()}
                    maxWidth="xs"
                    fullWidth
                >
                    <DialogTitle>{errorDialogTitle}</DialogTitle>
                    <DialogContent>
                        <Typography>
                            {errorDialogMessage}
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => handleDismissErrorDialog()}
                            variant="contained"
                            color="primary"
                        >
                            Ok
                        </Button>
                    </DialogActions>
                </Dialog>
            </Container >
        </LocalizationProvider >
    );
};

export default GrowthCalculator;



