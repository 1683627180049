import { BrowserRouter as Router, Routes, Route, Navigate, Outlet } from 'react-router-dom';
import { Helmet } from "react-helmet";

import Dashboard from './pages/Dashboard';
import Auth from './pages/Auth';
import Landing from './pages/Landing';
import LegalPages from './pages/LegalPages';

import './App.css';
import { IsLoggedIn } from './services/AuthService';

function App() {
  const ProtectedRoute = () => {
    const isLoggedIn = IsLoggedIn();
    return isLoggedIn ? <Outlet /> : <Navigate to="/auth" replace />;
  }

  return (
    <>
      {/* Helmet for Referrer Policy */}
      <Helmet>
        <meta name="referrer" content="strict-origin-when-cross-origin" />
      </Helmet>
      <Router>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/auth" element={<Auth />} />
          <Route path="/privacy" element={<LegalPages />} />
          <Route path="/terms" element={<LegalPages />} />

          <Route element={<ProtectedRoute />}>
            <Route path="/dashboard" element={<Dashboard />} />
          </Route>
        </Routes>
      </Router>
    </>
  );
}

export default App;
